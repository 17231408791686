<template>
  <div>
    <div>
      <div style="display: flex; justify-content: space-between">
        <!-- @click="initEmps"> 搜索, @keydown.enter.native="initEmps" 回车键调用初始化会员方法 -->
        <!-- clearable @clear="initEmps" 清空 -->
        <!-- :disabled="showAdvanceSearchVisible" 展开高级搜索时隐藏 -->
        <div style="margin-top: 10px">
          <el-input
            style="width: 300px; margin-right: 10px"
            prefix-icon="el-icon-search"
            v-model="empName"
            placeholder="请输入员工名进行搜索..."
            @keydown.enter.native="initEmps"
            clearable
            @clear="initEmps"
            :disabled="showAdvanceSearchVisible"
          ></el-input>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="initEmps('basic')"
            :disabled="showAdvanceSearchVisible"
            >搜索
          </el-button>
          <!-- @click="showAdvanceSearchVisible = !showAdvanceSearchVisible" 判断图标样式 -->
          <el-button type="primary" @click="showAdvanceSearchView">
            <i
              :class="
                showAdvanceSearchVisible
                  ? 'fa fa-angle-double-up'
                  : 'fa fa-angle-double-down'
              "
              aria-hidden="true"
            ></i
            >高级搜索
          </el-button>
        </div>
        <div>
          <!-- 导入数据 上传组件用自己的按钮 -->
          <!-- on-success 文件上传成功时的钩子, on-error 文件上传失败时的钩子; -->
          <!-- :disabled="importDataDisabled" 导入的时候禁用导入按钮 -->
          <!-- :headers="headers" 设置上传的请求头部 -->
          <el-upload
            style="display: inline-flex; margin-right: 8px"
            :show-file-list="false"
            :headers="headers"
            :before-upload="beforeUpload"
            :on-success="onSuccess"
            :on-error="onError"
            :disabled="importDataDisabled"
            action="api/employee/basic/import"
          >
            <el-button
              type="success"
              :icon="importDataBtnIcon"
              :disabled="importDataDisabled"
            >
              {{ importDataBtnText }}
            </el-button>
          </el-upload>
          <!-- @click="exportData" 导出数据 -->
          <el-button type="success" @click="exportData">
            <i class="el-icon-download" aria-hidden="true"></i>
            {{ outputDataBtnText }}
          </el-button>
          <!-- @click="exportData" office在线编辑 -->
          <el-button type="primary" @click="openOnlyOffice">
            <i class="el-icon-upload" aria-hidden="true"></i>
            {{ officeBtnText }}
          </el-button>
          <!-- @click="showAddEmpView" -->
          <el-button type="primary" @click="showAddEmpView">
            <i class="el-icon-upload"></i>
            添加员工
          </el-button>
        </div>
      </div>
      <!-- 高级搜索条件框 -->
      <!-- v-show="showAdvanceSearchVisible" 高级搜索条件框 -->
      <!-- <transition name="slide-fade"> 添加展开动画效果,包含整个搜索条件框-->
      <!-- v-model="searchValue.xxxxx" 绑定搜索条件数据 -->
      <transition name="slide-fade">
        <div
          v-show="showAdvanceSearchVisible"
          style="
            border: 1px solid #379ff5;
            border-radius: 5px;
            box-sizing: border-box;
            padding: 5px;
            margin: 10px 0;
          "
        >
          <el-row>
            <el-col :span="5">
              政治面貌：
              <el-select
                v-model="searchValue.politicId"
                placeholder="请选择政治面貌"
                size="mini"
                style="width: 130px"
              >
                <el-option
                  v-for="item in politicsstatus"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="4">
              民族：
              <el-select
                v-model="searchValue.nationId"
                placeholder="民族"
                size="mini"
                style="width: 130px"
              >
                <el-option
                  v-for="item in nations"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="4">
              职位：
              <el-select
                v-model="searchValue.posId"
                placeholder="职位"
                size="mini"
                style="width: 130px"
              >
                <el-option
                  v-for="item in positions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="4">
              职称：
              <el-select
                v-model="searchValue.jobLevelId"
                placeholder="职称"
                size="mini"
                style="width: 130px"
              >
                <el-option
                  v-for="item in joblevels"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              聘用形式：
              <el-radio-group v-model="searchValue.engageForm">
                <el-radio label="劳动合同">劳动合同</el-radio>
                <el-radio label="劳务合同">劳务合同</el-radio>
              </el-radio-group>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10px">
            <!-- 处理部门 v-model="advanveSearchDepVisible" -->
            <el-col :span="5">
              所属部门：
              <el-popover
                placement="bottom"
                title="请选择部门"
                width="220"
                trigger="manual"
                v-model="advanveSearchDepVisible"
              >
                <!-- 添加树形控件 default-expand-all	是否默认展开所有节点 ，节点点击事件 @node-click="handleNodeClick" -->
                <el-tree
                  :data="allDeps"
                  :props="defaultProps"
                  default-expand-all
                  @node-click="searchHandleNodeClick"
                ></el-tree>
                <!-- @node-click="searchHandleNodeClick" -->
                <!-- node-click	节点被点击时的回调 共三个参数，依次为：传递给 data 属性的数组中该节点所对应的对象、节点对应的 Node、节点组件本身。 -->
                <!-- 自定义点击事件 -->
                <!-- @click="advanceSearchShowDepView" -->
                <div
                  slot="reference"
                  style="
                    width: 130px;
                    display: inline-flex;
                    border-radius: 5px;
                    border: 1px solid #dedede;
                    height: 28px;
                    cursor: pointer;
                    align-items: center;
                    font-size: 12px;
                    padding-left: 8px;
                    box-sizing: border-box;
                  "
                  @click="advanceSearchShowDepView"
                >
                  {{ inputDepName }}
                </div>
                <!-- 回显数据 {{inputDepName}} -->
              </el-popover>
            </el-col>
            <!-- 处理日期：v-model="searchValue.beginDateScope" value-format="yyyy-MM-dd" ;
                 unlink-panels 两个面板各自独立切换当前年份  -->
            <el-col :span="10">
              入职日期：
              <el-date-picker
                unlink-panels
                size="mini"
                v-model="searchValue.beginDateScope"
                type="datetimerange"
                range-separator="至"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-col>
            <el-col :span="5" :offset="4">
              <el-button size="mini" @click="showAdvanceSearchView"
                >取消</el-button
              >
              <el-button
                type="primary"
                icon="el-icon-search"
                size="mini"
                @click="initEmps('advanced')"
                >搜索
              </el-button>
            </el-col>
          </el-row>
        </div>
      </transition>
    </div>
    <div style="margin-top: 10px">
      <!-- 员工表格(显示所有员工) -->
      <el-table
        :data="emps"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        style="width: 100%"
        stripe
        border
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          prop="name"
          label="姓名"
          align="center"
          fixed
          width="90"
        >
        </el-table-column>
        <el-table-column prop="gender" label="性别" align="center" width="40">
        </el-table-column>
        <el-table-column prop="workID" label="工号" align="center" width="85">
        </el-table-column>
        <el-table-column
          prop="birthday"
          label="出生日期"
          align="center"
          width="95"
        >
        </el-table-column>
        <el-table-column
          prop="idCard"
          label="身份证号"
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="wedlock"
          label="婚姻状态"
          align="center"
          width="70"
        >
        </el-table-column>
        <el-table-column
          prop="nation.name"
          label="民族"
          align="center"
          width="50"
        >
        </el-table-column>
        <el-table-column
          prop="nativePlace"
          label="籍贯"
          align="center"
          width="80"
        >
        </el-table-column>
        <el-table-column
          prop="politicsStatus.name"
          label="政治面貌"
          align="center"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="email"
          label="电子邮件"
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="phone"
          label="电话号码"
          align="center"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="address"
          label="联系地址"
          align="center"
          width="220"
        >
        </el-table-column>
        <el-table-column
          prop="department.name"
          label="所属部门"
          align="center"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="position.name"
          label="职位"
          align="center"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="joblevel.name"
          label="级别"
          align="center"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="engageForm"
          label="聘用形式"
          align="center"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="tiptopDegree"
          label="最高学历"
          align="center"
          width="80"
        >
        </el-table-column>
        <el-table-column
          prop="school"
          label="毕业学校"
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="specialty"
          label="所属专业"
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="workState"
          label="在职状态"
          align="center"
          width="70"
        >
        </el-table-column>
        <el-table-column
          prop="beginDate"
          label="入职日期"
          align="center"
          width="95"
        >
        </el-table-column>
        <el-table-column
          prop="conversionTime"
          label="转正日期"
          align="center"
          width="95"
        >
        </el-table-column>
        <el-table-column
          prop="beginContract"
          label="合同起始日期"
          align="center"
          width="95"
        >
        </el-table-column>
        <el-table-column
          prop="endContract"
          label="合同截止日期"
          align="center"
          width="95"
        >
        </el-table-column>
        <el-table-column label="合同期限" align="center" width="100">
          <template slot-scope="scope">
            <el-tag>{{ scope.row.contractTerm }}</el-tag>
            年
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" fixed="right" width="300">
          <template slot-scope="scope">
            <!-- 25-4 给编辑按钮绑定点击事件 @click="showEditEmpView(scope.row)" -->
            <el-button
              style="padding: 3px"
              size="mini"
              @click="showEditEmpView(scope.row)"
              >编辑</el-button
            >
            <el-button style="padding: 3px" size="mini" type="primary" plain
              >查看高级资料</el-button
            >
            <!-- 24-1 删除员工 @click="deleteEmp(scope.row)" -->
            <el-button
              style="padding: 3px"
              size="mini"
              type="danger"
              @click="deleteEmp(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div style="display: flex; justify-content: flex-end; margin-top: 10px">
        <!-- @current-change="currentChange" 当前页
             @size-change="sizeChange" 每页显示多少条 -->
        <el-pagination
          prev-text="上一页"
          next-text="下一页"
          @current-change="currentChange"
          @size-change="sizeChange"
          :page-sizes="[10, 20, 30, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
        >
        </el-pagination>
      </div>
    </div>
    <!-- 添加、编辑员工弹框 -->
    <!-- :title="title" 将添加员工弹框标题改为变量,可以动态选择 -->
    <!-- 编辑员工,根据条件显示是添加还是编辑 -->
    <el-dialog
      :before-close="closeEmpView"
      :title="title"
      :visible.sync="dialogVisible"
      width="80%"
    >
      <div>
        <el-form ref="empRef" :model="emp" :rules="empRules">
          <el-row>
            <el-col :span="6">
              <el-form-item label="姓名：" prop="name">
                <el-input
                  style="width: 150px"
                  v-model="emp.name"
                  prefix-icon="el-icon-edit"
                  placeholder="请输入员工姓名"
                  size="mini"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="性别：" prop="gender">
                <el-radio-group style="margin-top: 9px" v-model="emp.gender">
                  <el-radio label="男">男</el-radio>
                  <el-radio label="女">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="出生日期：" prop="birthday">
                <el-date-picker
                  style="width: 150px"
                  v-model="emp.birthday"
                  type="date"
                  value-format="yyyy-MM-dd"
                  size="mini"
                  placeholder="出生日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="政治面貌：" prop="politicId">
                <el-select
                  style="width: 200px"
                  v-model="emp.politicId"
                  placeholder="请选择政治面貌"
                  size="mini"
                >
                  <el-option
                    v-for="item in politicsstatus"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="民族：" prop="nationId">
                <el-select
                  v-model="emp.nationId"
                  placeholder="民族"
                  size="mini"
                  style="width: 150px"
                >
                  <el-option
                    v-for="item in nations"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="籍贯：" prop="nativePlace">
                <el-input
                  v-model="emp.nativePlace"
                  placeholder="籍贯"
                  prefix-icon="el-icon-edit"
                  size="small"
                  style="width: 120px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="电子邮箱：" prop="email">
                <el-input
                  v-model="emp.email"
                  placeholder="请输入电子邮箱"
                  prefix-icon="el-icon-message"
                  size="mini"
                  style="width: 150px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="联系地址：" prop="address">
                <el-input
                  v-model="emp.address"
                  placeholder="请输入联系地址"
                  prefix-icon="el-icon-edit"
                  size="mini"
                  style="width: 200px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="职位：" prop="posId">
                <el-select
                  v-model="emp.posId"
                  placeholder="职位"
                  size="mini"
                  style="width: 150px"
                >
                  <el-option
                    v-for="item in positions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="职称：" prop="jobLevelId">
                <el-select
                  v-model="emp.jobLevelId"
                  placeholder="职称"
                  size="mini"
                  style="width: 150px"
                >
                  <el-option
                    v-for="item in joblevels"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="所属部门：" prop="departmentId">
                <!-- manual 手动弹出框 -->
                <el-popover
                  placement="bottom"
                  title="请选择部门"
                  width="200"
                  trigger="manual"
                  v-model="addDepVisible"
                >
                  <!-- el-tree 添加树形控件, default-expand-all 是否默认展开所有节点, @node-click="handleNodeClick" 节点点击事件 -->
                  <el-tree
                    :data="allDeps"
                    :props="defaultProps"
                    default-expand-all
                    @node-click="handleNodeClick"
                  ></el-tree>
                  <!-- node-click	节点被点击时的回调, 共三个参数, 依次为：传递给 data 属性的数组中该节点所对应的对象、节点对应的 Node、节点组件本身。 -->
                  <!-- 自定义点击事件 -->
                  <div
                    slot="reference"
                    style="
                      width: 150px;
                      display: inline-flex;
                      border-radius: 5px;
                      border: 1px solid #dedede;
                      height: 28px;
                      cursor: pointer;
                      align-items: center;
                      font-size: 12px;
                      padding-left: 8px;
                      box-sizing: border-box;
                    "
                    @click="addShowDepView"
                  >
                    {{ inputDepName }}
                  </div>
                  <!-- {{inputDepName}} 回显数据 -->
                </el-popover>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="电话号码：" prop="phone">
                <el-input
                  v-model="emp.phone"
                  placeholder="请输入电话号码"
                  size="mini"
                  style="width: 200px"
                  prefix-icon="el-icon-phone"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="工号：" prop="workID">
                <el-input
                  v-model="emp.workID"
                  placeholder="请输入工号"
                  size="mini"
                  style="width: 150px"
                  prefix-icon="el-icon-edit"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <!-- 数据在 data 中写死的 -->
              <el-form-item label="学历：" prop="tiptopDegree">
                <el-select
                  v-model="emp.tiptopDegree"
                  placeholder="职称"
                  size="mini"
                  style="width: 150px"
                >
                  <el-option
                    v-for="item in tiptopDegrees"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="毕业院校：" prop="school">
                <el-input
                  v-model="emp.school"
                  placeholder="请输入学校"
                  size="mini"
                  style="width: 150px"
                  prefix-icon="el-icon-edit"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="专业名称：" prop="specialty">
                <el-input
                  v-model="emp.specialty"
                  placeholder="请输入专业名称"
                  size="mini"
                  style="width: 200px"
                  prefix-icon="el-icon-edit"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="入职日期：" prop="beginDate">
                <el-date-picker
                  v-model="emp.beginDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  size="mini"
                  style="width: 120px"
                  placeholder="入职日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="转正日期：" prop="conversionTime">
                <el-date-picker
                  v-model="emp.conversionTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  size="mini"
                  style="width: 120px"
                  placeholder="转正日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="合同起始日期：" prop="beginContract">
                <el-date-picker
                  v-model="emp.beginContract"
                  type="date"
                  value-format="yyyy-MM-dd"
                  size="mini"
                  style="width: 135px"
                  placeholder="合同起始日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="合同截止日期：" prop="endContract">
                <el-date-picker
                  v-model="emp.endContract"
                  type="date"
                  value-format="yyyy-MM-dd"
                  size="mini"
                  style="width: 170px"
                  placeholder="合同截止日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="身份证号码：" prop="idCard">
                <el-input
                  v-model="emp.idCard"
                  placeholder="请输入身份证号码"
                  size="mini"
                  prefix-icon="el-icon-edit"
                  style="width: 180px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="聘用形式：" prop="engageForm">
                <el-radio-group
                  v-model="emp.engageForm"
                  style="margin-top: 8px"
                >
                  <el-radio label="劳动合同">劳动合同</el-radio>
                  <el-radio label="劳务合同">劳务合同</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="婚姻状况：" prop="wedlock">
                <el-radio-group v-model="emp.wedlock" style="margin-top: 8px">
                  <el-radio label="未婚">未婚</el-radio>
                  <el-radio label="已婚">已婚</el-radio>
                  <el-radio label="离异">离异</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <!-- @click="AddAndEditEmp" 添加员工 -->
        <el-button type="primary" @click="AddAndEditEmp">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "EmpBasic",
  data() {
    return {
      // 添加员工
      emp: {
        id: null,
        name: "",
        gender: "",
        birthday: "",
        idCard: "",
        wedlock: "",
        nationId: null,
        nativePlace: "",
        politicId: null,
        email: "",
        phone: "",
        address: "",
        departmentId: null,
        jobLevelId: null,
        posId: null,
        engageForm: "",
        tiptopDegree: "",
        specialty: "",
        school: "",
        beginDate: "",
        workState: "在职",
        workID: "",
        contractTerm: null,
        conversionTime: "",
        notworkDate: null,
        beginContract: "",
        endContract: "",
        workAge: null,
        salaryId: null,
      },
      // 添加员工: 民族
      nations: [],
      // 添加员工: 职称
      joblevels: [],
      // 添加员工: 政治面貌
      politicsstatus: [],
      // 添加员工:职位
      positions: [],
      // 添加员工: 部门
      department: [],
      // 添加员工: 部门弹出框
      addDepVisible: false,
      // 高级搜索: 部门弹出框
      advanveSearchDepVisible: false,
      // 部门树形控件
      defaultProps: {
        children: "children",
        label: "name",
      },
      // 树形控件, 绑定所属部门数据对象
      allDeps: [],
      // 添加员工: 学历(数据写死了)
      tiptopDegrees: [
        "博士",
        "硕士",
        "本科",
        "大专",
        "高中",
        "初中",
        "小学",
        "其它",
      ],
      // 回显部门数据
      inputDepName: "",
      // 添加编辑员工弹框动态标题(可以是添加也可以是编辑)
      title: "",
      // 高级搜索条件对象
      searchValue: {
        // 政治面貌
        politicId: null,
        // 民族
        nationId: null,
        // 职位
        posId: null,
        // 职称
        jobLevelId: null,
        // 聘用形式
        engageForm: null,
        // 部门 id
        departmentId: null,
        // 入职日期范围
        beginDateScope: null,
      },
      // 高级搜索框动态效果
      showAdvanceSearchVisible: false,
      // 获取所有员工（分页）
      emps: [],
      // 添加loading
      loading: false,
      // 分页总条数
      total: 0,
      // 默认显示第1页(currentPage 后端字段）
      currentPage: 1,
      // 15、默认每页显示 10 条
      size: 10,
      // 搜索
      empName: "",
      // 添加员工弹框
      dialogVisible: false,
      // 导入按钮,默认: 不禁用
      importDataDisabled: false,
      // 导入显示文本,默认: 导入数据
      importDataBtnText: "导入数据",
      outputDataBtnText: "导出数据",
      officeBtnText: "在线编辑",
      // 导入显示按钮,默认: el-icon-upload2
      importDataBtnIcon: "el-icon-upload2",
      // 为导出数据定义请求头
      headers: {
        Authorization: window.sessionStorage.getItem("tokenStr"),
      },
      // 表单数据校验
      empRules: {
        name: [{ required: true, message: "请输入员工名", trigger: "blur" }],
        gender: [
          { required: true, message: "请输入员工性别", trigger: "blur" },
        ],
        birthday: [
          { required: true, message: "请输入出生日期", trigger: "blur" },
        ],
        idCard: [
          { required: true, message: "请输入身份证号码", trigger: "blur" },
          {
            pattern:
              /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
            message: "身份证号码不正确",
            trigger: "blur",
          },
        ],
        wedlock: [
          { required: true, message: "请输入婚姻状况", trigger: "blur" },
        ],
        nationId: [{ required: true, message: "请输入民族", trigger: "blur" }],
        nativePlace: [
          { required: true, message: "请输入籍贯", trigger: "blur" },
        ],
        politicId: [
          { required: true, message: "请输入政治面貌", trigger: "blur" },
        ],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          { type: "email", message: "邮箱地址格式不正确", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入电话号码", trigger: "blur" },
          {
            pattern:
              /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/,
            message: "请输入合法手机号码",
            trigger: "blur",
          },
        ],
        address: [{ required: true, message: "请输入地址", trigger: "blur" }],
        departmentId: [
          { required: true, message: "请输入部门名称", trigger: "blur" },
        ],
        jobLevelId: [
          { required: true, message: "请输入职称", trigger: "blur" },
        ],
        posId: [{ required: true, message: "请输入职位", trigger: "blur" }],
        engageForm: [
          { required: true, message: "请输入聘用形式", trigger: "blur" },
        ],
        tiptopDegree: [
          { required: true, message: "请输入学历", trigger: "blur" },
        ],
        specialty: [{ required: true, message: "请输入专业", trigger: "blur" }],
        school: [
          { required: true, message: "请输入毕业院校", trigger: "blur" },
        ],
        beginDate: [
          { required: true, message: "请输入入职日期", trigger: "blur" },
        ],
        workState: [
          { required: true, message: "请输入工作状态", trigger: "blur" },
        ],
        workID: [{ required: true, message: "请输入工号", trigger: "blur" }],
        contractTerm: [
          { required: true, message: "请输入合同期限", trigger: "blur" },
        ],
        conversionTime: [
          { required: true, message: "请输入转正日期", trigger: "blur" },
        ],
        notworkDate: [
          { required: true, message: "请输入离职日期", trigger: "blur" },
        ],
        beginContract: [
          { required: true, message: "请输入合同起始日期", trigger: "blur" },
        ],
        endContract: [
          { required: true, message: "请输入合同结束日期", trigger: "blur" },
        ],
        workAge: [{ required: true, message: "请输入工龄", trigger: "blur" }],
      },
    };
  },
  mounted() {
    // 初始化添加员工下拉框数据
    this.initData();
    // 获取所有员工（分页）
    this.initEmps();
    // 获取职位
    this.initPositions();
  },
  methods: {
    // 添加员工弹窗
    showAddEmpView() {
      // 清空表单
      this.emp = {
        id: null,
        name: "",
        gender: "",
        birthday: "",
        idCard: "",
        wedlock: "",
        nationId: null,
        nativePlace: "",
        politicId: null,
        email: "",
        phone: "",
        address: "",
        departmentId: null,
        jobLevelId: null,
        posId: null,
        engageForm: "",
        tiptopDegree: "",
        specialty: "",
        school: "",
        beginDate: "",
        workState: "在职",
        workID: "",
        contractTerm: null,
        conversionTime: "",
        notworkDate: null,
        beginContract: "",
        endContract: "",
        workAge: null,
        salaryId: null,
      };
      // 清空部门信息
      this.inputDepName = "";
      // 点击添加员工按钮时，弹出框标题为:添加员工
      this.title = "添加员工";
      // 取最大工号
      this.getMaxWorkID();
      // 初始化职位信息
      this.initPositions();
      // 弹出框
      this.dialogVisible = true;
    },
    // 编辑员工弹窗
    showEditEmpView(data) {
      // 点击编辑员工按钮时，弹出框标题为:编辑员工信息
      this.title = "编辑员工信息";
      // 回显员工数据
      this.emp = data;
      // console.log(data)
      // 回显部门信息
      this.inputDepName = data.department.name;
      // 初始化职位信息
      this.initPositions();
      // 弹出框
      this.dialogVisible = true;
    },
    // 关闭弹窗
    closeEmpView() {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.dialogVisible = false;
          this.$message({
            type: "info",
            message: "已取消",
          });
        })
        .catch((_) => {});
    },
    // 添加或编辑员工:有 id 编辑员工,没有 id 添加员工
    // 添加和编辑这里就请求方式不一样: putRequest 或 postRequest, 其它的都一样
    AddAndEditEmp() {
      if (this.emp.id) {
        // 有 id 编辑员工
        this.$refs["empRef"].validate((valid) => {
          if (valid) {
            this.putRequest("/employee/basic/", this.emp).then((resp) => {
              if (resp) {
                this.dialogVisible = false;
                this.initEmps();
              }
            });
          }
        });
      } else {
        // 没有id 添加员工
        // empRef 表单中定义的引用对象 ref="empRef"
        this.$refs["empRef"].validate((valid) => {
          if (valid) {
            this.postRequest("/employee/basic/", this.emp).then((resp) => {
              if (resp) {
                this.dialogVisible = false;
                this.initEmps();
              }
            });
          }
        });
      }
    },
    // 分页:size 每页显示多少条, 默认会把 size 传进来
    sizeChange(size) {
      this.size = size;
      this.initEmps();
    },
    // 分页:currentPage 当前页, 点击的时候自己会带过来
    currentChange(currentPage) {
      this.currentPage = currentPage; // 16
      this.initEmps(); // 18、调用方法
    },
    // 初始化添加员工下拉框数据, 树控件节点点击事件
    handleNodeClick(data) {
      this.inputDepName = data.name;
      this.emp.departmentId = data.id;
      // 弹框
      this.addDepVisible = !this.addDepVisible;
    },
    // 初始化添加员工下拉框数据, 添加员工(所属部门)
    addShowDepView() {
      // 弹出框
      this.addDepVisible = !this.addDepVisible;
    },
    // 初始化添加员工下拉框数据, 添加员工(获取最大号)
    getMaxWorkID() {
      this.getRequest("/employee/basic/maxworkid").then((resp) => {
        if (resp) {
          this.emp.workID = resp.obj;
        }
      });
    },
    // 初始化添加员工下拉框数据, 添加员工(获取职位)有可能变动, 打开对话框的时候调用此方法
    initPositions() {
      this.getRequest("/employee/basic/positions").then((resp) => {
        if (resp) {
          this.positions = resp;
        }
      });
    },
    // 初始化添加员工下拉框数据, 不怎么变动的数据放 sessionStorage,就不用怎么去查
    initData() {
      // 获取民族数据:先从 sessionStorage 里取，取不到再调用接口获取数据
      if (!window.sessionStorage.getItem("nations")) {
        this.getRequest("/employee/basic/nations").then((resp) => {
          this.nations = resp;
          // 存到 sessionStorage 里,把对象转字符串
          window.sessionStorage.setItem("nations", JSON.stringify(resp));
        });
      } else {
        // 从 sessionStorage 获取，字符串转对象
        this.nations = JSON.parse(window.sessionStorage.getItem("nations"));
      }
      // 获取职称:先从 sessionStorage 里取，取不到再调用接口获取数据
      if (!window.sessionStorage.getItem("joblevels")) {
        this.getRequest("/employee/basic/joblevels").then((resp) => {
          if (resp) {
            this.joblevels = resp;
            window.sessionStorage.setItem("joblevels", JSON.stringify(resp));
          }
        });
      } else {
        // 从 sessionStorage 获取，字符串转对象
        this.joblevels = JSON.parse(window.sessionStorage.getItem("joblevels"));
      }
      // 获取政治面貌:先从 sessionStorage 里取，取不到再调用接口获取数据
      if (!window.sessionStorage.getItem("politicsstatus")) {
        this.getRequest("/employee/basic/politicsstatus").then((resp) => {
          if (resp) {
            this.politicsstatus = resp;
            window.sessionStorage.setItem(
              "politicsstatus",
              JSON.stringify(resp)
            );
          }
        });
      } else {
        // 从 sessionStorage 获取，字符串转对象
        this.politicsstatus = JSON.parse(
          window.sessionStorage.getItem("politicsstatus")
        );
      }
      // 获取所属部门:树形控件,绑定所属部门数据对象
      if (!window.sessionStorage.getItem("allDeps")) {
        this.getRequest("/employee/basic/deps").then((resp) => {
          if (resp) {
            this.allDeps = resp;
            window.sessionStorage.setItem("allDeps", JSON.stringify(resp));
          }
        });
      } else {
        this.allDeps = JSON.parse(window.sessionStorage.getItem("allDeps"));
      }
    },
    // 删除员工
    deleteEmp(data) {
      this.$confirm(
        "此操作将永久删除员工 [" + data.name + " ] , 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.deleteRequest("/employee/basic/" + data.id).then((resp) => {
            if (resp) {
              this.initEmps();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 获取所有员工（分页）
    initEmps(type) {
      let url;
      // 添加 loading
      this.loading = true;

      if (type && (type === "basic" || type === "advanced")) {
        // 定义搜索 url
        url = "/employee/basic/?currentPage=1" + "&size=" + this.size;
      } else {
        // ?currentPage='+this.currentPage+'&size='+this.size 添加分页参数
        url =
          "/employee/basic/?currentPage=" +
          this.currentPage +
          "&size=" +
          this.size;
      }
      if (type && type === "advanced") {
        // 高级搜索
        if (this.searchValue.politicId) {
          url += "&politicId=" + this.searchValue.politicId;
        }
        if (this.searchValue.nationId) {
          url += "&nationId=" + this.searchValue.nationId;
        }
        if (this.searchValue.posId) {
          url += "&posId=" + this.searchValue.posId;
        }
        if (this.searchValue.jobLevelId) {
          url += "&jobLevelId=" + this.searchValue.jobLevelId;
        }
        if (this.searchValue.engageForm) {
          url += "&engageForm=" + this.searchValue.engageForm;
        }
        if (this.searchValue.departmentId) {
          url += "&departmentId=" + this.searchValue.departmentId;
        }
        if (this.searchValue.beginDateScope) {
          url += "&beginDateScope=" + this.searchValue.beginDateScope;
        }
      } else {
        // '&name='+this.empName 添加用户名搜索参数(传参: 根据条件搜索，不传参: 查询所有)
        url += "&name=" + this.empName;
      }
      this.getRequest(url).then((resp) => {
        // 关闭 loading
        this.loading = false;
        if (resp) {
          this.emps = resp.data;
          // 分页
          this.total = resp.total;
        }
      });
    },
    // 高级搜索弹窗
    showAdvanceSearchView() {
      // 清空表单
      this.searchValue = {
        // 政治面貌
        politicId: null,
        // 民族
        nationId: null,
        // 职位
        posId: null,
        // 职称
        jobLevelId: null,
        // 聘用形式
        engageForm: null,
        // 部门 id
        departmentId: null,
        // 入职日期范围
        beginDateScope: null,
      };
      this.inputDepName = null;
      this.showAdvanceSearchVisible = !this.showAdvanceSearchVisible;
      if (!this.showAdvanceSearchVisible) {
        this.initEmps();
      }
    },
    // 高级搜索, 部门点击事件
    searchHandleNodeClick(data) {
      this.inputDepName = data.name;
      this.searchValue.departmentId = data.id;
      // 部门弹框
      this.advanveSearchDepVisible = !this.advanveSearchDepVisible;
    },
    // 高级搜索, 部门弹框
    advanceSearchShowDepView() {
      this.advanveSearchDepVisible = !this.advanveSearchDepVisible;
    },
    // 数据导入成功 恢复原来的图标和状态
    onSuccess() {
      this.importDataBtnIcon = "el-icon-upload2";
      this.importDataBtnText = "导入数据";
      this.importDataDisabled = false; // 不禁用导入按钮
      this.initEmps();
    },
    // 数据导入失败 恢复原来的图标和状态
    onError() {
      this.importDataBtnIcon = "el-icon-upload2";
      this.importDataBtnText = "导入数据";
      this.importDataDisabled = false; // 不禁用导入按钮
    },
    // 导入数据, 改变图标和添加 loading 状态
    beforeUpload() {
      this.importDataBtnIcon = "el-icon-loading";
      this.importDataBtnText = "正在导入";
      this.importDataDisabled = true; // 禁用导入按钮
    },
    // 下载请求
    exportData() {
      this.downloadRequest("/employee/basic/export");
    },
    // 跳转在线编辑页面
    openOnlyOffice()
    {
      window.location.href = 'http://60.204.135.82:9004/index.php?mod=explorer#recent';
    },
  },
};
</script>

<style scoped>
/* 展开收起条件搜索框动画样式 */
/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
  transition: all 0.8s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>